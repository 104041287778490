import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const faqApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Faq", "FaqById", "DeletedFaq"],
  endpoints: (builder) => ({
    getFaqList: createQueryForList(builder, "/faq/group/active", ["Faq"]),

    getFaqById: createQueryById(builder, "/faq/group/active", ["FaqById"]),

    getDeletedFaqList: createQueryForList(builder, "/faq/group/deleted", [
      "DeletedFaq",
    ]),

    deleteFaq: createDeleteRestoreMutation(builder, "/faq/group/bulk/delete", [
      "DeletedFaq",
    ]),

    permanentDeleteFaq: createDeleteRestoreMutation(
      builder,
      "/faq/group/bulk/perma-delete",
      ["DeletedFaq"]
    ),

    restoreFaqList: createDeleteRestoreMutation(
      builder,
      "/faq/group/bulk/restore",
      ["Faq", "DeletedFaq"]
    ),

    createFaq: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append("categories[]", data.categories[i]);
          }
        }
        ["categories"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/faq/group/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Faq"],
    }),

    updateFaqInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append("categories[]", data.categories[i]);
          }
        }
        ["categories"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/faq/group/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Faq", "FaqById"],
    }),
  }),
});
export const {
  useGetFaqListQuery,
  useGetFaqByIdQuery,
  useGetDeletedFaqListQuery,
  useDeleteFaqMutation,
  usePermanentDeleteFaqMutation,
  useRestoreFaqListMutation,
  useCreateFaqMutation,
  useUpdateFaqInfoMutation,
} = faqApiSlice;
