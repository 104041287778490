import React, { useEffect } from "react";
import ProgressBar from "../Components/Core/ProgressBar";
import Logo from "../assets/images/logo-light.png";
import PerfectScrollbar from "perfect-scrollbar";
import {
  MenuItem,
  MenuDropdown,
  MenuDropDownItem,
  MenuTitle,
} from "../Components/Core/MenuItem";
import { useContext } from "react";
import GlobalContext from "../utils/GlobalContext";
import { Link } from "react-router-dom";

const menuItemsAndLinks = [
  {
    type: "dropdown",
    name: "Users",
    icon: "bi-people-fill",
    permission: ["super"],
    Users: [{ "Add New": "/user/add" }, { "All Users": "/users" }],
  },
  {
    type: "dropdown",
    name: "Categories",
    icon: "bi-diagram-3",
    permission: ["admin", "super"],
    Categories: [
      { "Add New": "/category/add?type=blog" },
      { "All Categories": "/categories" },
      { "Deleted Categories": "/category/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Articles",
    icon: "bi-file-earmark-richtext",
    permission: ["admin", "super"],
    Articles: [
      { "Add New": "/article/add" },
      { "All Articles": "/articles" },
      { "Deleted Articles": "/article/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Menu",
    icon: "bi-menu-app-fill",
    permission: ["admin", "super"],
    Menu: [
      { "Manage Menu": "/menus" },
      { "Add New": "/menu/add" },
      { "Deleted Menu": "/menu/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Team",
    icon: "bi-people",
    permission: ["admin", "super"],
    Team: [
      { "Add New": "/team/add" },
      { "Team Members": "/teams" },
      { "Deleted Members": "/team/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Testimonials",
    icon: "bi-chat-left-quote",
    permission: ["admin", "super"],
    Testimonials: [
      { "Add New": "/testimonial/add" },
      { "Testimonials List": "/testimonials" },
      { "Deleted Testimonials": "/testimonial/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "FAQS",
    icon: "bi-question-circle",
    permission: ["admin", "super"],
    FAQS: [
      { "Add New": "/faq/add" },
      { "Faqs List": "/faqs" },
      { "Deleted Faqs": "/faq/deleted" },
    ],
  },

  {
    type: "title",
    name: "Blogs",
  },
  {
    type: "dropdown",
    name: "Authors",
    icon: "bi-person-lines-fill",
    permission: ["admin", "super"],
    Authors: [
      { "Add New": "/blog/author/add" },
      { "All Authors": "/blog/authors" },
      { "Deleted Authors": "/blog/author/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Blogs",
    icon: "bi-pencil-square",
    permission: ["admin", "super"],
    Blogs: [
      { "Add New": "/blog/add" },
      { "All Blogs": "/blogs" },
      { "Deleted Blogs": "/blog/deleted" },
    ],
  },

  {
    type: "dropdown",
    name: "Comments",
    permission: ["admin", "super"],
    icon: "bi-chat-quote",
    Comments: [
      { "Add New": "/blog/comment/add" },
      { "All Comments": "/blog/comments" },
      { "Deleted Comments": "/blog/comment/deleted" },
    ],
  },

  {
    type: "title",
    name: "Travel",
  },
  {
    type: "dropdown",
    name: "Package",
    icon: "bi-compass",
    permission: ["admin", "super"],
    Package: [
      { "Add New": "/package/add" },
      { "All Packages": "/packages" },
      { "Deleted Packages": "/package/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "TripInfo",
    icon: "bi-info-circle",
    permission: ["admin", "super"],
    TripInfo: [
      { "Add New": "/tripinfo/add" },
      { "All Trip Info": "/tripinfos" },
      { "Deleted Trip Infos": "/tripinfo/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Booking",
    icon: "bi-basket3-fill",
    permission: ["super"],
    Booking: [{ "All Booking": "/bookings" }, { "All inquiry": "/inquiries" }],
  },
  {
    type: "title",
    name: "Media",
  },
  {
    type: "dropdown",
    name: "Album",
    icon: "bi-easel",
    permission: ["admin", "super"],
    Album: [
      { "Add New": "/album/add" },
      { "All Album": "/albums" },
      { "Deleted Album": "/album/deleted" },
    ],
  },
  {
    type: "dropdown",
    name: "Media",
    icon: "bi-collection-play",
    permission: ["admin", "super"],
    Media: [
      { "Add New": "/media/add" },
      { "All Media": "/allmedia" },
      { "Deleted Media": "/media/deleted" },
    ],
  },
];

const Sidebar = () => {
  useEffect(() => {
    const container = document.querySelector("#sidebar-scroll-container");
    const ps = new PerfectScrollbar(container);
  });

  const globalData = useContext(GlobalContext);

  return (
    <aside
      className={
        globalData?.sidebar_skin
          ? "sidebar " + globalData?.sidebar_skin
          : "sidebar dark"
      }
    >
      <div className="sidebar-container">
        <div className="sidebar-header">
          <Link className="navbar-brand" to="/">
            <img
              src={
                globalData?.logo_light
                  ? process.env.REACT_APP_IMAGE_URL + globalData?.logo_light
                  : Logo
              }
              alt={
                globalData?.company_name
                  ? globalData?.company_name
                  : "Flamingo It Studio"
              }
            />
          </Link>
          <div className="data-usage">
            <ProgressBar
              percentValue={65}
              fact="4.5 / 5"
              description="progress"
            />
          </div>
        </div>

        <div
          className="sidebar-body"
          id="sidebar-scroll-container"
          data-perfact-scrollbar="true"
        >
          <nav>
            <ul className="navbar-nav">
              <li className="menu-title">Menu</li>
              {menuItemsAndLinks.map((menuItem, idx) => {
                switch (menuItem.type) {
                  case "dropdown":
                    return (
                      <MenuDropdown
                        key={idx}
                        href={menuItem[menuItem.name]}
                        iconClass={menuItem.icon}
                        title={menuItem.name}
                        hideMenu={menuItem?.permission?.includes(
                          localStorage.getItem("admin_type")
                        )}
                      >
                        {menuItem[menuItem.name].map((subMenu, jdx) => {
                          return (
                            <MenuDropDownItem
                              key={jdx}
                              href={subMenu[Object.keys(subMenu)[0]]}
                              title={Object.keys(subMenu)[0]}
                            />
                          );
                        })}
                      </MenuDropdown>
                    );
                  case "title":
                    return <MenuTitle title={menuItem.name} />;
                  case "link":
                    return (
                      <MenuItem
                        key={menuItem.name}
                        href={menuItem[menuItem.name]}
                        iconClass={menuItem.icon}
                        title={menuItem.name}
                      />
                    );
                }
              })}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
