import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const authorApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Author", "AuthorById", "DeletedAuthor"],
  endpoints: (builder) => ({
    getAuthorList: createQueryForList(builder, "/blog/author/active", [
      "Author",
    ]),

    getAuthorById: createQueryById(builder, "/blog/author", ["AuthorById"]),

    getDeletedAuthorList: createQueryForList(builder, "/blog/author/deleted", [
      "DeletedAuthor",
    ]),

    deleteAuthor: createDeleteRestoreMutation(
      builder,
      "/blog/author/bulk/delete",
      ["DeletedAuthor"]
    ),

    permanentDeleteAuthor: createDeleteRestoreMutation(
      builder,
      "/blog/author/bulk/perma-delete",
      ["DeletedAuthor"]
    ),

    restoreAuthorList: createDeleteRestoreMutation(
      builder,
      "/blog/author/bulk/restore",
      ["Author", "DeletedAuthor"]
    ),

    createAuthor: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/blog/author/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Author"],
    }),

    updateAuthorInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/blog/author/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Author", "AuthorById"],
    }),
  }),
});
export const {
  useGetAuthorListQuery,
  useGetAuthorByIdQuery,
  useGetDeletedAuthorListQuery,
  useDeleteAuthorMutation,
  usePermanentDeleteAuthorMutation,
  useRestoreAuthorListMutation,
  useCreateAuthorMutation,
  useUpdateAuthorInfoMutation,
} = authorApiSlice;
