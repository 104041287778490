import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const testimonialApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Testimonial", "TestimonialById", "DeletedTestimonial"],
  endpoints: (builder) => ({
    getTestimonialList: createQueryForList(builder, "/testimonial/active", [
      "Testimonial",
    ]),

    getTestimonialById: createQueryById(builder, "/testimonial/active", [
      "TestimonialById",
    ]),

    getDeletedTestimonialList: createQueryForList(
      builder,
      "/testimonial/deleted",
      ["DeletedTestimonial"]
    ),

    deleteTestimonial: createDeleteRestoreMutation(
      builder,
      "/testimonial/bulk/delete",
      ["DeletedTestimonial"]
    ),

    permanentDeleteTestimonial: createDeleteRestoreMutation(
      builder,
      "/testimonial/bulk/perma-delete",
      ["DeletedTestimonial"]
    ),

    restoreTestimonialList: createDeleteRestoreMutation(
      builder,
      "/testimonial/bulk/restore",
      ["Testimonial", "DeletedTestimonial"]
    ),

    createTestimonial: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        if (data.package_id) {
          for (let i = 0; i < data.package_id.length; i++) {
            webKitFormData.append("package_id[]", data.package_id[i]);
          }
        }

        if (data.members) {
          for (let i = 0; i < data.members.length; i++) {
            webKitFormData.append("members[]", data.members[i]);
          }
        }

        ["package_id", "members"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        return {
          url: "/testimonial/add",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Testimonial"],
    }),

    updateTestimonialInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        if (data.package_id) {
          for (let i = 0; i < data.package_id.length; i++) {
            webKitFormData.append("package_id[]", data.package_id[i]);
          }
        }
        if (data.members) {
          for (let i = 0; i < data.members.length; i++) {
            webKitFormData.append("members[]", data.members[i]);
          }
        }

        ["package_id", "members"].forEach((e) => delete data[e]);
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/testimonial/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Testimonial", "TestimonialById"],
    }),
  }),
});
export const {
  useGetTestimonialListQuery,
  useGetTestimonialByIdQuery,
  useGetDeletedTestimonialListQuery,
  useDeleteTestimonialMutation,
  usePermanentDeleteTestimonialMutation,
  useRestoreTestimonialListMutation,
  useCreateTestimonialMutation,
  useUpdateTestimonialInfoMutation,
} = testimonialApiSlice;
